"use client";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from '../utils/store';
import FilterComponent from "./SearchComponent";

interface HeaderProps {
  onSearch: (
    event:any
  ) => void;
}
const Header: React.FC<HeaderProps> = ({ onSearch: onSearch }) => {
  const navigate = useNavigate();
  const { setIsAdmin } = useStore();
  function logOut() {
    setIsAdmin('');
    navigate('/');
  }

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">

      <div className="d-flex align-items-center justify-content-between logopart">
        <Link to="/dashboard" className="logo d-flex align-items-center">

          <img src="../../assets/img/loumac-logo.png" />
        </Link>
        <i className="bi bi-list toggle-sidebar-btn"></i>
      </div>
      <div><FilterComponent onSearch={onSearch} /></div>
      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center ">

          <li className="nav-item dropdown pe-3">

            <div className="nav-link nav-profile d-flex align-items-center pe-0" onClick={logOut}  >
              <img src="../../assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" />
              <span className="d-none d-md-block ps-2">Logout</span>
            </div>

          </li>

        </ul>
      </nav>

    </header>
  );
};

export default Header;