"use client";
import { useEffect } from 'react';
import { useStore } from '../../../utils/store';
import { useNavigate, useLocation } from 'react-router-dom';
import { setLocalAccessToken, setLocalIDToken, setLocalRefreshToken } from '../../../utils/contants';

const LoginProcess: React.FC = () => {
  const navigate = useNavigate();
  const { setIsAdmin } = useStore();
  const location = useLocation();

  useEffect(() => {
    const Login = async (accessToken: string, idToken: string, refreshToken: string) => {
      try {
        // Store tokens in localStorage
        setLocalAccessToken(accessToken);
        setLocalIDToken(idToken);
        setLocalRefreshToken(refreshToken);
        // Set admin state
        setIsAdmin(accessToken);
        // Navigate to dashboard
        navigate('/dashboard');
      } catch (error) {
        // Redirect to login on failure
        navigate('/');
      }
    };

    // Parse query parameters
    const params = new URLSearchParams(location.search);
    const accessToken = params.get('access_token');
    const idToken = params.get('id_token');
    const refreshToken = params.get('refresh_token');

    if (accessToken && idToken && refreshToken) {
      Login(accessToken, idToken,refreshToken);
    } else {
      navigate('/');
    }
  }, [location, navigate, setIsAdmin]);

  return <div className="processing-login">Processing login...</div>;
};

export default LoginProcess;
