import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  TableHead,
} from "@mui/material";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
} from "@mui/icons-material";
import { count } from "console";

interface DataGridCompProps {
  rows: any[];
  columns: any[];
  paginationModel: { page: number; pageSize: number };
  handleRowClick: (data: any) => void;
  handlePagination: (data: { offset: number; limit: number }) => void;
  totalRecords: number;
  handleStatus: (data: any) => void;
}

// Custom Pagination Actions Component
const TablePaginationActions: React.ComponentType<any> = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) => {
  const theme = useTheme();

  const handlePageButtonClick = (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => {
    onPageChange(event, newPage);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={(event) => handlePageButtonClick(event, 0)}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={(event) => handlePageButtonClick(event, page - 1)}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={(event) => handlePageButtonClick(event, page + 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={(event) =>
          handlePageButtonClick(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
        }
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

// Main Custom Table Component
const CustomPaginationActionsTable: React.FC<DataGridCompProps> = ({
  rows,
  columns,
  paginationModel,
  handleRowClick,
  handlePagination,
  totalRecords,
  handleStatus,
}) => {
  const [page, setPage] = useState(paginationModel.page);
  const [rowsPerPage, setRowsPerPage] = useState(paginationModel.pageSize);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    handlePagination({ offset: newPage, limit: rowsPerPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
   
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    handlePagination({ offset: 0, limit: newRowsPerPage });
    console.log(event.target.value);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.field} style={{ fontFamily: "Inter", fontSize: "16px" }}>
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rows).map((row) => (
            <TableRow key={row.id || row.name} onClick={
              ((event: any)=>{
              if(event.target.type == 'checkbox')  {
                event.preventDefault();
                handleStatus(row);
              }else{
                handleRowClick(row);
              }
              //  
              })
            } >
              
              {columns.map((column) => (
                <TableCell key={column.field} style={{ fontFamily: "Inter", fontSize: "14px" }}>
                  {column.renderCell
                    ? column.renderCell(row[column.field], row)
                    : column.valueGetter
                    ? column.valueGetter(row[column.field], row)
                    : row[column.field] ?? "-"}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[2,5, 10, 25, { label: "All", value: -1 }]}
              colSpan={columns.length}
              count={totalRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              
              ActionsComponent={TablePaginationActions} // Fixed ActionsComponent type
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default CustomPaginationActionsTable;
