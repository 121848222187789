
import Paper from '@mui/material/Paper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
interface DatePickerComponentProps {
    onChange: (
        event: any
    ) => void;
    name: string;
    defaultValue: string
}
const DatePickerComponent: React.FC<DatePickerComponentProps> = ({ onChange: onChange, name: name, defaultValue: defaultValue }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
                className='custom-dt-picker'
                format="MMM DD, YYYY"
                name={name}
                defaultValue={dayjs(defaultValue)}
                views={['year', 'month', 'day']}
                onChange={onChange}
            />
        </LocalizationProvider>
    );
}
export default DatePickerComponent;