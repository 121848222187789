import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Index';
import Login from './pages/Login/Index';
import { StoreProvider } from './utils/store';
import Users from './pages/Users/Index';
import DataManagement from './pages/DataManagement/Index';
import Quote from './pages/Quote/Index';
import Contractors from './pages/Contractors/Index';
import LoginProcess from './pages/Login/process/Index';
import Terms from './pages/Terms/Index';
import Tenders from './pages/Tender/Index';

const App: React.FC = () => {
  return (
    <Router>
      <StoreProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/quote" element={<Quote />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/management" element={<DataManagement />} />
          <Route path="/management/users" element={<Users />} />
          <Route path="/management/users/:currentId" element={<Users />} />
          <Route path="/management/contractors" element={<Contractors />} />
          <Route path="/management/contractors/:currentId" element={<Contractors />} />
          <Route path="/management/terms" element={<Terms />} />
          <Route path="/management/terms/:currentId" element={<Terms />} />

          <Route path="/management/tenders" element={<Tenders />} />
          <Route path="/management/tenders/:currentId" element={<Tenders />} />

          <Route path="/login/success" element={<LoginProcess />} />
          <Route path="/login/failed" element={<Login />} />
        </Routes>
      </StoreProvider>
    </Router>
  );
};

export default App;
