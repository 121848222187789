"use client";
import React, { useEffect, useState } from "react";
import "./Index.css";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import {
  Checkbox,
  Fab,
  FormControlLabel,
  Paper,
  TextareaAutosize,
} from "@mui/material";
import { GridAddIcon, GridColDef } from "@mui/x-data-grid";
import PageTitle from "../../components/PageTitle";

import Constants from "../../utils/contants";
import HttpService from "../../services/HttpService";
import { useStore } from "../../utils/store";
import Switch from "@mui/material/Switch";
import { useNavigate, useParams } from "react-router-dom";
import CustomPaginationActionsTable from "../../components/TablePagination";

const Terms: React.FC = () => {
  const { getAdmin } = useStore();
  const { currentId } = useParams<{ currentId: any }>();
  const navigate = useNavigate();

  // Columns definition for the table
  const columns: any[] = [
    { field: "termText", headerName: "Text", width: 250 },
    {
      field: "status",
      headerName: "Active",
      renderCell: (value: any) => (
        <Switch
          checked={value == "1"}
          size="small"
          color="success"
          inputProps={{ "aria-label": "controlled" }}
        />
      ),
    },
    {
      field: "updated",
      headerName: "Last Updated",
      valueGetter: (value: any) =>
        new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
    },
    {
      field: "created",
      headerName: "Date Created",
      width: 130,
      valueGetter: (value: any) =>
        new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
    },
  ];

  // State variables
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isAddForm, setIsAddForm] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const [paginate, setPaginate] = useState({ limit: Constants.DEFAULT_PAGE_SIZE, offset: 0 });
  const [termsForm, setTermsForm] = useState({ termText: "" ,id:null});

  // Fetch data when component loads or dependencies change
  useEffect(() => {
    if (currentId) {
      if (currentId === "Add") {
        setIsAddForm(true);
      } else {
        fetchSingleRecord(currentId);
      }
    } else {
      setIsAddForm(false);
      setCurrentRecord(null);
    }
  }, [currentId]);

  useEffect(() => {
    fetchData();
  }, [searchQuery, paginate]);

  // Fetch terms list
  const fetchData = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.termsURL,
        { headers: { Authorization: `Bearer ${getAdmin()}` } },
        { search: searchQuery, limit: paginate.limit, offset: paginate.offset },
      );
      if (response.success) {
        setRows(response.data.paginateRecords);
        setTotalRecords(response.data.total);
      } else {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      }
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
    }
  };

  // Fetch single term record
  const fetchSingleRecord = async (id: string) => {
    try {
      const response = await HttpService.get(
        `${Constants.apiURL + Constants.termsURL}/${id}`,
        { headers: { Authorization: `Bearer ${getAdmin()}` } },
        null
      );
      if (response.success) {
        handleRowClick(response.terms);
      } else {
        setErrorMessage("Failed to fetch record. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Failed to fetch record. Please try again.");
    }
  };

  // Handle form input change
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setTermsForm((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle record creation or update
  const handleSave = async () => {
    setLoading(true);
    const reqData: any = termsForm;
    const endpoint = 
       `${Constants.apiURL + Constants.termsURL}`
      let response: any;
    try {
      if(currentRecord) {
        response = await HttpService.put(endpoint, reqData, {
          headers: { Authorization: `Bearer ${getAdmin()}` },
        });
      }else{
        response = await HttpService.post(endpoint, reqData, {
          headers: { Authorization: `Bearer ${getAdmin()}` },
        });
      }
      
      if (response.success) {
        fetchData();
        clearForm(false);
      } else {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      }
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
    } finally {
      setLoading(false);
    }
  };

  // Handle status toggle
  const handleStatus = async (row: any) => {
    const newStatus = row.status === "1" ? "0" : "1";
    try {
      const response = await HttpService.patch(
        Constants.apiURL + Constants.termsURL,
        { status: newStatus,id:row.id },
        { headers: { Authorization: `Bearer ${getAdmin()}` } }
      );
      if (response.success) {
        fetchData();
      } else {
        setErrorMessage("Failed to update status. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Failed to update status. Please try again.");
    }
  };

  // Clear form and reset state
  const clearForm = (isForm: boolean) => {
    setIsAddForm(false);
    setTermsForm({ termText: "",id: null });
    setErrorMessage("");
    setCurrentRecord(null);
    navigate("/management/terms");
  };

  const handleRowClick = (record: any) => {
    setCurrentRecord(record);
    setTermsForm({ termText: record.termText,id: record.id });
    setIsAddForm(true);
  };

  const handlePagination = (paginationData: any) => setPaginate(paginationData);

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value);

  return (
    <div>
      <Header onSearch={handleSearchInput} />
      <SideBar />
      <main id="main" className="main dashboardmain">
        <PageTitle
          title={["Home", "Data Management", "Standard Terms & Conditions"]}
          links={["/dashboard", "/management", ""]}
        />
        <section className="section dashboard">
          <div className="containergrid">
            <div className="mt-2">
              {!isAddForm && (
                <Paper sx={{ width: "100%" }} className="mt-4">
                  <CustomPaginationActionsTable
                    rows={rows}
                    columns={columns}
                    totalRecords={totalRecords}
                    handlePagination={handlePagination}
                    handleRowClick={(row) => navigate(`/management/terms/${row.id}`)}
                    paginationModel={{ page: paginate.offset, pageSize: paginate.limit }}
                    handleStatus={handleStatus}
                  />
                </Paper>
              )}
            </div>
            {isAddForm && (
              <div className="formElement">
                <form className="container loginform">
                  <div className="mb-3">
                    <label className="form-label">Text</label>
                    <TextareaAutosize
                      className="form-control form-control-loum"
                      name="termText"
                      value={termsForm.termText}
                      onChange={handleInputChange}
                      minRows={7}
                    />
                  </div>
                  <div className="mb-3 mt-3 txt-center" style={{ textAlign: "left" }}>
                    <button
                      type="button"
                      className="btn btn-primary loginbtn createbtn"
                      disabled={loading}
                      onClick={handleSave}
                    >
                      {loading
                        ? "Saving..."
                        : currentRecord
                        ? "Update"
                        : "Create"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary loginbtn resetbtn"
                      onClick={() => clearForm(false)}
                    >
                      Cancel
                    </button>
                    {errorMessage && <p className="text-danger">{errorMessage}</p>}
                  </div>
                </form>
              </div>
            )}
          </div>
        </section>
        {!isAddForm && (
          <Fab
            aria-label="add"
            sx={fabStyle}
            onClick={() => navigate("/management/terms/Add")}
          >
            <GridAddIcon />
          </Fab>
        )}
      </main>
    </div>
  );
};

const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
  backgroundColor: "#000",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#000",
    color: "#fff",
  },
};

export default Terms;
