import React, { useEffect } from 'react';
import './Index.css';

import SideBar from '../../components/Sidebar';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';




const Dashboard: React.FC = () => {

  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.remove('loginbody');

  }, []);
  const handleSerchInput = async (event: any) => {
    //todo search action needs to be implemented
  }
  return (
    <div>
      <Header onSearch={handleSerchInput} />
      <SideBar />
      <main id="main" className="main dashboardmain">
        <PageTitle
          title={["Welcome Admin"]}
          links={[]}
        />
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-8">
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Dashboard;
