import { BiBorderLeft, BiBorderRight } from "react-icons/bi";
import { SiWindows,SiDask,SiNintendogamecube } from "react-icons/si";
import { Link,useLocation } from "react-router-dom";
const SideBar: React.FC = () => {

  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  const isActiveFlexible = (path: string) => location.pathname.indexOf(path) !== -1;
  return (
    <aside id="sidebar" className="sidebar">

    <ul className="sidebar-nav" id="sidebar-nav">

       
    <div>
      <li className="nav-item active"  >
        <Link className="nav-link collapsed"  to="/dashboard"   style={isActive('/dashboard') ? styles.activeLink : styles.link} >
         <SiWindows/>
          <span className="ms-2">Dashboard</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link collapsed"  to="/management"  style={isActiveFlexible('/management')  ? styles.activeLink : styles.link} >
        <SiDask/>
          <span className="ms-2">Data Management</span>
        </Link >
      </li>
      <li className="nav-item">
        <Link className="nav-link collapsed" to="/quote"  style={isActive('/quote') ? styles.activeLink : styles.link} >
         <SiNintendogamecube/>
          <span className="ms-2">Quote</span>
        </Link>
      </li>

    </div>


    </ul>

  </aside>
    
  );
};
const styles = {
  nav: {
    display: 'flex',
    gap: '20px',
    padding: '10px',
    backgroundColor: '#f4f4f4',
  },
  link: {
    textDecoration: 'none',
    // color: 'black',
  },
  activeLink: {
    textDecoration: 'none',
    color: '#000B29',
    fontWeight: 'bold',
    backgroundColor: '#aaaaaa',
    borderLeft: '2px solid #000B29'
  },
};
export default SideBar;