"use client";
import { useEffect } from 'react';
import './Index.css';
import Constants from '../../utils/contants';
const Login: React.FC = () => {
  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.add('loginbody');

  }, []);
  // useEffect(() => {
  const Login = async () => {
    return CognitoLogin();
  };
  const CognitoLogin = async () => {
    const urlPart = encodeURI('client_id=' + Constants.CLIENTID + '&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=' + Constants.apiURL + '/cognito/callback');
    window.location.href = 'https://loumac.auth.ap-southeast-2.amazoncognito.com/login?' + urlPart;
  }
  return (
    <div className="main">
      <div className="row">
        <div className="col-8 leftside">
          <div className="logologin">
            <img src="../../assets/img/loumac-logo.png" alt='LoumacPro Logo' />
          </div>
          <div className="leftbottombox">
            <h2 className="mainhead">
              Loumac Pro
            </h2>
            <p className="para1">
              Commercial and Infrastructure construction in Sydney. Confident and experienced, our team
              ensures quality builds no matter the project.
            </p>
            <div className="emptyline">

            </div>
            <div className="emptyline white">

            </div>
            <div className="emptyline">

            </div>
            <div className="contact-fields">
              <p>Ph - 0401 600 935</p>
              <p>Email - admin@loumac.com.au</p>
              <p>Address - Unit 2 34-36 Plasser Rd North St Marys</p>
            </div>
          </div>
        </div>
        <div className="col-4 rightbox">
          <div className="rightside">
            <div className="loginbox">

              <h3 className="maintitle">Welcome back!</h3>
              <h4 className="subtitle">Login into Your Account</h4>

              <form className="container loginform">
                {/* <div className="mb-3">
                          <label  className="form-label">Email ID*</label>
                          <input type="email" className="form-control form-control-loum" id="emailForm"
                              placeholder="yourname@company.com"  value={email} onChange={handleEmail}  />
                      </div>
                      <div className="mb-3">
                          <label  className="form-label">Password*</label>
                          <input type="password" className="form-control form-control-loum"
                              id="passwordForm" placeholder="****************"  value={password} onChange={handlePassword}  />
                      </div>
                      <div className="row">
                          <div className="col">
                              <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="remember" value="true"
                                      id="rememberMe"></input>
                                  <label className="form-check-label" >Keep me signed in</label>
                              </div>
                          </div>
                          <div className="col" style={{textAlign:'right'}}>
                              <div className="form-check">

                                <Link to='/reset-password' ><label className="form-check-label">Forgot Password?</label></Link>  
                              </div>
                          </div>
                      </div> */}
                <div className="mb-3 mt-3 txt-center" style={{ textAlign: 'center' }}>
                  <button type="button" className="btn btn-primary loginbtn" onClick={Login}>Login</button>

                </div>

                {/* <div className="mb-3 mt-3 txt-center" style={{textAlign:'center'}}>
                          <button type="button" className="btn btn-primary loginbtn" onClick={CognitoSignup}>Signup</button>
                          {errorMesssage && <p className="text-danger">{errorMesssage}</p>}
                      </div> */}
                <div className="mb-3 mt-3 txtloginbottom" style={{ textAlign: 'center' }}>
                  Contact Loumac for access
                </div>

              </form>
              <div className="mb-3 mt-3 footer" style={{ textAlign: 'center' }}>
                <span style={{ color: '#898989', fontSize: '14px' }}>2024 Copyrights </span>
                <span style={{ color: '#000B29', fontSize: '14px' }}>© Loumac Pro</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
