import React, { useEffect } from 'react';
import './Index.css';

import SideBar from '../../components/Sidebar';
import Header from '../../components/Header';

import { Link } from 'react-router-dom';
import { Box, Grid2, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import {  CheckBoxOutlined, DocumentScannerOutlined, GroupAddOutlined } from '@mui/icons-material';
import PageTitle from '../../components/PageTitle';


const DataManagement: React.FC = () => {

  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.remove('loginbody');

  }, []);
  const handleSerchInput = async (event: any) => {
    console.log(event);
  }
  return (
    <div>
      <Header onSearch={handleSerchInput} />
      <SideBar />
      <main id="main" className="main dashboardmain">
      <PageTitle
          title={["Data Management"]}
          links={[]}
        />

       

    <Box sx={{ flexGrow: 1 }}>
          <Grid2 container spacing={1}>
            <Grid2 size={3} className="iconbox">
            <Link to='/management/users'><GroupAddOutlined /> User Management </Link>
            </Grid2>
            <Grid2 size={3} className="iconbox">
            <Link to='/management/contractors'><GroupAddOutlined /> Contractor Management </Link>
            </Grid2>
            <Grid2 size={3} className="iconbox">
            <Link to='/management/terms'><CheckBoxOutlined /> Terms Management </Link>
            </Grid2>
            <Grid2 size={3} className="iconbox">
            <Link to='/management/tenders'><DocumentScannerOutlined /> Tenders Management </Link>
            </Grid2>
          </Grid2>
        </Box>

      </main>
    </div>
  );
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));


export default DataManagement;
