const Constants = {
  // baseURL: "http://localhost:3000",
  //apiURL: "http://localhost:3000",
  apiURL: 'https://api.loumacpro.com.au',

  usersURL: "/api/v1/users",
  rolesURL: "/api/v1/users/roles",
  contractorsURL: "/api/v1/contractors",
  termsURL: "/api/v1/terms",
  tendersURL: "/api/v1/tenders",

  USER_POOL_REGION: 'ap-southeast-2',
  REFRESH_TOKEN_URL: `/cognito/refresh-token`,
  CLIENTID: "3n7896ir9vpphef1jjkqmbkh8l",
  DEFAULT_PAGE_SIZE: 25,
  COMMON_SERVER_ERROR: 'Process Failed',
};
export default Constants;
export const getLocalIDToken = () => {
  return localStorage.getItem('louidtoken')
}

export const setLocalIDToken = (IdToken: any) => {
  localStorage.setItem('louidtoken', IdToken)
}
export const getLocalAccessToken = () => {
  return localStorage.getItem('louaccesstoken')
}

export const setLocalAccessToken = (IdToken: any) => {
  localStorage.setItem('louaccesstoken', IdToken)
}
export const getLocalRefreshToken = () => {
  return localStorage.getItem('louRefreshToken')
}

export const setLocalRefreshToken = (IdToken: any) => {
  localStorage.setItem('louRefreshToken', IdToken)
}

export const getCurrentDate = () => {
  return new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
}
