"use client";
import React, { useEffect, useRef, useState } from "react";
import { styled } from '@mui/material/styles';
import "./Index.css";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Fab, Paper, TextareaAutosize } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import Constants, { getCurrentDate } from "../../utils/contants";
import HttpService from "../../services/HttpService";
import { useStore } from "../../utils/store";
import Switch from "@mui/material/Switch";
import CustomPaginationActionsTable from "../../components/TablePagination";

import StatusRequestData from "../../Models/StatusRequest";
import { useNavigate, useParams } from "react-router-dom";
import { Add } from "@mui/icons-material";
import DragAndDropFileUpload from "../../components/FileUploadComponent";
import DatePickerComponent from "../../components/DatePickerComponent";
const Tenders: React.FC = () => {
  const isInitialLoad = useRef(true);
  const { getAdmin } = useStore();
  const { currentId } = useParams<{ currentId: any }>();
  const navigate = useNavigate();
  const columns: any[] = [
    { field: "tenderType", headerName: "Type", width: 150 },
    { field: "docName", headerName: "Document Name", flex: 1 },
    {
      field: "expiryDate", headerName: "Expiry Date", flex: 1, valueGetter: (value: any, row: any) => {
        return new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      }
    },
    { field: "notes", headerName: "Document Notes", flex: 1 },
    { field: "creator", headerName: "User Uploaded", flex: 1 },
    {
      field: "status",
      width: 100,
      headerName: "Active",
      renderCell: (value: any, row: any) => (
        <Switch
          checked={value === "1" ? true : false}
          size="small"
          color={"success"}
          inputProps={{ "aria-label": "controlled" }}
        />
      ),
    },
    {
      field: "created",
      flex: 1,
      headerName: "Date Uploaded",
      valueGetter: (value: any, row: any) => {
        return new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      },
    },
  ];

  const [searchQuery, setSearchQuery] = useState<any>("");
  const [uploadFile, setUploadFile] = useState<any>("");
  const [paginate, setPaginate] = useState<any>({ limit: Constants.DEFAULT_PAGE_SIZE, offset: 0 });
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [errorMesssage, setErrorMessage] = useState<any>(null);
  const [isAddForm, setIsAddForm] = useState<boolean>(false);
  const tenderType = ["Insurances", "Capability Statement", "Certificate and Accreditations"];
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const [errors, setErrors] = useState({
    tenderType: "",
    docName: "",
    expiryDate: "",
    fileURL: "",
    uploadFile: ""
  });

  const validateForm = () => {
    const newErrors: any = {};

    // Name Validation
    if (!tenderForm.tenderType.trim()) {
      newErrors.tenderType = "Type  is required.";
    }
    if (!tenderForm.docName) {
      newErrors.docName = "Document Name is required.";
    }

    if (!tenderForm.expiryDate) {
      newErrors.expiryDate = "Expiry Date  is required.";
    }
    if (!uploadFile) {
      newErrors.uploadFile = "File  is required.";
    }
    // Phone Number Validation

    // if (!tenderForm.fileURL) {
    //   newErrors.fileURL = "File is required.";
    // }
    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };
  interface tenderFormFields {
    tenderType: string,
    docName: string,
    expiryDate: string,
    id?: string | null,
    notes: string,
    fileURL: string,
    fileName?: string

  }
  const [tenderForm, setTenderForm] = useState<tenderFormFields>({
    tenderType: "Insurances",
    docName: "",
    expiryDate: getCurrentDate(),
    id: null,
    notes: "",
    fileURL: "",
    fileName: ""

  });

  useEffect(() => {
    if (currentId) {
      if (currentId === 'Add') {
        setIsAddForm(true);
      } else {
        fetchSingleRecord(currentId);
      }
    } else {
      setIsAddForm(false);
      setCurrentRecord(null);
    }
  }, [currentId]);
  useEffect(() => {
    if ((searchQuery || paginate) || isInitialLoad.current) {
      fetchData();
    }
    isInitialLoad.current = false;
  }, [searchQuery, paginate]);
  const CreateOrUpdate = async (formData: any) => {
    try {
      const response = await HttpService.postWithFileUpload(
        Constants.apiURL + Constants.tendersURL,
        formData,
        { headers: { Authorization: "Bearer " + getAdmin(), 'Content-Type': 'multipart/form-data' } }
      );
      if (response.success) {
        fetchData();
        clearForm(false);
      } else {
        setErrorMessage("Failed to add tender. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add tender. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const Update = async (dataObj: any, id: number) => {
    try {
      dataObj['id'] = id;
      const response = await HttpService.put(
        Constants.apiURL + Constants.tendersURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
        fetchData();
        clearForm(false);
      } else {
        setErrorMessage("Failed to add tender. Please try again.");
      }
      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add tender. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const StatusUpdate = async (dataObj: StatusRequestData) => {
    try {
      const response = await HttpService.patch(
        Constants.apiURL + Constants.tendersURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
        fetchData();
        clearForm(false);
      } else {
        setErrorMessage("Failed to Update Status. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add tender. Please try again.");
      console.error("Error fetching data:", error);
    }
  };


  const fetchSingleRecord = async (id: any) => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.tendersURL + '/' + id,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        null
      );
      if (response.success) {

        handleRowClick(response.tender);
      } else {
        setErrorMessage("Failed to add tender. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add tender. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const fetchData = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.tendersURL,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        { search: searchQuery, limit: paginate.limit, offset: paginate.offset },
      );
      if (response.success) {
        setRows(response.data.paginateRecords);

        setTotalRecords(response.data.total);
      } else {
        setErrorMessage("Failed to add tender. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add tender. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const handleStatus = (row: any) => {
    let status = '1';
    if (row.status === '1') {
      status = '0';
    }
    if (row.status === '0') {
      status = '1';
    }
    StatusUpdate({ id: row.id, status: status });
  };







  const clearForm = (isForm: boolean) => {
    setIsAddForm(false);
    setTenderForm({
      expiryDate: "",
      docName: "",
      fileURL: "",
      id: null,
      notes: "",
      tenderType: ''

    });
    setCurrentRecord(null);
    setErrorMessage('');
    navigate('/management/tenders')
  };
  const handleRowClick = async (record: any) => {
    setCurrentRecord(record);
    setIsAddForm(true);
    setTenderForm(
      {
        expiryDate: record.expiryDate,
        docName: record.docName,
        fileURL: record.fileURL,
        id: record.id,
        notes: record.notes,
        tenderType: record.tenderType,
        fileName: record.fileName

      }
    );

  };
  const handlePagination = (paginationData: any) => {
    console.log(paginationData);
    setPaginate(paginationData);
  };
  const handleSerchInput = async (event: any) => {
    const value: any = event.target.value;
    setSearchQuery(value);
  };

  const handleSave = async () => {

    if (validateForm()) {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', uploadFile);
      formData.append('expiryDate', tenderForm.expiryDate);
      formData.append('docName', tenderForm.docName);
      formData.append('notes', tenderForm.notes);
      formData.append('tenderType', tenderForm.tenderType);
      if (currentRecord) {
        formData.append('id', currentRecord.id);

      }
      await CreateOrUpdate(formData);
      setLoading(false);
    }
  }
  const handleExpiryDate = (value: any) => {
    const expiryDateValue = value.year() + '-' + (value.month() + 1) + '-' + value.date();
    setTenderForm((prev: any) => {
      return { ...prev, ['expiryDate']: expiryDateValue };
    });
  }

  const handleFileChange = (file: any) => {
    setUploadFile(file);
  }
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLFormElement>
  ) => {
    const { name, value, type } = e.target;
    setTenderForm((prev: any) => {
      // Check if the input is a checkbox
      if (type === "checkbox") {
        const target: any = e.target;
        const currentValues = prev[name] || []; // Get the current array or initialize it
        if (target.checked && currentValues.includes(value) === false) {
          return { ...prev, [name]: [...currentValues, value] };
        } else {
          return { ...prev, [name]: currentValues.filter((id: any) => id !== value) };
        }
      } else {
        console.log(value);
        // Handle other input types
        return { ...prev, [name]: value };
      }
    });
  };

  return (
    <div>
      <Header onSearch={handleSerchInput} />
      <SideBar />
      <main id="main" className="main dashboardmain">
        <PageTitle
          title={["Home", "Data Management", "Tender Management"]}
          links={["/dashboard", "/management", ""]}
        />

        <section className="section dashboard">
          <div className="containergrid">
            <div className="mt-2">
              {!isAddForm && (
                <Paper sx={{ width: "100%" }} className="mt-4">
                  <CustomPaginationActionsTable
                    rows={rows}
                    columns={columns}
                    totalRecords={totalRecords}
                    handlePagination={handlePagination}
                    handleRowClick={(row) => {
                      navigate('/management/tenders/' + row.id)
                      //console.log(data);
                    }}
                    paginationModel={{ page: paginate.offset, pageSize: paginate.limit }}
                    handleStatus={handleStatus}
                  />
                </Paper>
              )}
              {isAddForm && (
                <div className="formElement">
                  <div className="row formheader">
                    <div className="col">
                      <h1 className="pageheader">Document</h1>
                    </div>
                  </div>

                  <form className="container loginform">
                    <div className="mb-3">
                      <label className="form-label">Type*</label>
                      <select
                        className="form-select"
                        id="tenderTypeForm"
                        aria-label="Default select example"
                        name="tenderType"
                        value={tenderForm.tenderType}
                        onChange={(e: any) => {
                          handleInputChange(e);
                        }}
                      >
                        <option value=''>Document Type</option>
                        {tenderType.map((item, index) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                      {errors.tenderType && <span style={errorStyle}>{errors.tenderType}</span>}
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Document Name*</label>
                      <input
                        type="text"
                        className="form-control form-control-loum"
                        id="docNameForm"
                        name="docName"
                        placeholder=""
                        value={tenderForm.docName}
                        onChange={handleInputChange}
                      />
                      {errors.docName && <span style={errorStyle}>{errors.docName}</span>}
                    </div>
                    <div className="mb-3">
                      <div className="form-label">Expiry Date*</div>
                      <div>
                        <DatePickerComponent name="expiryDate" onChange={handleExpiryDate} defaultValue={tenderForm.expiryDate} />
                      </div>
                      {errors.expiryDate && <span style={errorStyle}>{errors.expiryDate}</span>}
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Notes</label>
                      <TextareaAutosize
                        className="form-control form-control-loum"
                        placeholder="Enter notes"
                        name="notes"
                        value={tenderForm.notes}
                        onChange={handleInputChange}
                        minRows={7}
                      />
                    </div>

                    <div className="mb-3">
                      <DragAndDropFileUpload name="fileURL" defaultValue={{ name: tenderForm.fileName, file: tenderForm.fileURL }} onChange={handleFileChange} />
                      {errors.uploadFile && <span style={errorStyle}>{errors.uploadFile}</span>}
                    </div>
                    <div
                      className="mb-3 mt-3 txt-center"
                      style={{ textAlign: "left" }}
                    >
                      <button
                        type="button"
                        id={"createbtn"}
                        className="btn btn-primary  createbtn"
                        disabled={loading}
                        onClick={() => {
                          handleSave();
                        }}
                      >
                        {loading
                          ? "Adding wait..."
                          : currentRecord
                            ? "Update Document"
                            : "Create Document"}
                      </button>
                      <button
                        type="button"
                        id={"resetbtn"}
                        className="btn btn-primary loginbtn resetbtn"
                        onClick={() => {
                          clearForm(false);
                        }}
                      >
                        Cancel
                      </button>
                      {errorMesssage && (
                        <p className="text-danger">{errorMesssage}</p>
                      )}
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </section>
        {!isAddForm && (
          <Fab
            aria-label="add"
            sx={fabStyle}
            onClick={() => {
              navigate('/management/tenders/Add');
            }}
          >
            <Add />
          </Fab>
        )}
      </main>
    </div>
  );
};
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
  backgroundColor: "#000",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#000",
    color: "#fff",
  },
};
const uploadStyle = {
  width: '100%',
  height: '200px',
  color: '#464A53',
  background: 'transparent',
  border: '1px solid #DDDFE1'
}
const errorStyle = {
  color: "red",
};
export default Tenders;
