"use client";
import React, { useEffect, useRef, useState } from "react";
import "./Index.css";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Checkbox, Fab, FormControlLabel, Paper } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import Constants from "../../utils/contants";
import HttpService from "../../services/HttpService";
import { useStore } from "../../utils/store";
import Switch from "@mui/material/Switch";

import CustomPaginationActionsTable from "../../components/TablePagination";
import UserRequest from "../../Models/UserRequest";
import StatusRequestData from "../../Models/StatusRequest";
import { useNavigate, useParams } from "react-router-dom";
import { Add } from "@mui/icons-material";


const Users: React.FC = () => {
  const isInitialLoad = useRef(true);
  const { getAdmin } = useStore();
  const { currentId } = useParams<{ currentId: any }>();
  const navigate = useNavigate();
  const columns: any[] = [
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "lastName", headerName: "Last Name", flex: 1 },
    { field: "email", headerName: "Email Address", flex: 1 },
    { field: "phone", headerName: "Phone Number", flex: 1 },
    { field: "empType", headerName: "Employee Type", flex: 1 },
    {
      field: "contractorName", headerName: "Contracter Name", flex: 1, valueGetter: (value: any, row: any) => {
        return (!value) ? 'N/A' : value;
      },
    },
    {
      field: "roleId",
      width: 200,
      headerName: "User Role",
      valueGetter: (value: any, row: any) => {
        return row.roleNames;
      },
    },
    {
      field: "status",
      width: 100,
      headerName: "Active",
      renderCell: (value: any, row: any) => (
        <Switch
          checked={value === "1" ? true : false}
          size="small"
          color={"success"}
          inputProps={{ "aria-label": "controlled" }}
        />
      ),
    },
    { field: "statusInfo", headerName: "Confirmation Status", flex: 1 },
    {
      field: "created",
      flex: 1,
      headerName: "Date Modified",
      valueGetter: (value: any, row: any) => {
        return new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      },
    },
  ];

  const [searchQuery, setSearchQuery] = useState<any>("");
  const [paginate, setPaginate] = useState<any>({ limit: Constants.DEFAULT_PAGE_SIZE, offset: 0 });
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [errorMesssage, setErrorMessage] = useState<any>(null);
  const [isAddForm, setIsAddForm] = useState<boolean>(false);
  const empType = ["Full Time", "Part Time", "Casual", "Contractor"];
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);
  const [contractorList, setContractorList] = useState<any>([]);
  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const [errors, setErrors] = useState({
    firstName: "",
    email: "",
    phone: "",
    lastName: "",
    empType: "",
    role: "",
    contractor: ""
  });

  const validateField = async (element: any) => {
    const elementName = element.name;
    const newErrors: any = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/; // Validates a 10-digit phone number
    switch (elementName) {
      case 'firstName':
        if (!userForm.firstName.trim()) {
          newErrors.firstName = "Firstname is required.";
        }
        break;
      case 'lastName':
        if (!userForm.lastName.trim()) {
          newErrors.lastName = "Lastname is required.";
        }
        break;
      case 'email':
        if (!element.value) {
          newErrors.email = "Email is required.";
        } else if (!emailRegex.test(element.value)) {
          newErrors.email = "Invalid email address.";
        } else {
          const isEmailExists = await checkEmail(element.value);
          console.log(isEmailExists);
          if(isEmailExists) {
            newErrors.email = "Email ID already Exists";
          }else{
            newErrors.email = '';
          }
        }
        break;
     
      case 'phone':
        if (!phoneRegex.test(userForm.phone) && userForm.phone) {
          newErrors.phone = "Invalid Australian phone number.";
        }
        break;
    }
    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  }
  const validateForm = () => {
    const newErrors: any = {};

    // Name Validation
    if (!userForm.firstName.trim()) {
      newErrors.firstName = "Firstname is required.";
    }
    if (!userForm.lastName) {
      newErrors.lastName = "Lastname is required.";
    }
    // Email Validation
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!userForm.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(userForm.email)) {
      newErrors.email = "Invalid email address.";
    }
    // Phone Number Validation
    const phoneRegex = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/; // Validates a 10-digit phone number
    if (!phoneRegex.test(userForm.phone) && userForm.phone) {
      newErrors.phone = "Invalid Australian phone number.";
    }
    if (!userForm.empTypeTxt) {
      newErrors.empType = "Employee Type is required.";
    }
    if (userForm.roleTxt.length === 0) {
      newErrors.role = "Select at least one Role.";
    }
    if (userForm.roleTxt.indexOf('7') !== -1 && !userForm.contractor) {
      newErrors.contractor = "Contractor is required.";
    }

    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };
  interface userFormFields {
    firstName: string,
    lastName: string,
    email: string,
    id?: string | null,
    phone: string,
    empTypeTxt: string
    roleTxt: any[],
    contractor: number
  }
  const [userForm, setUserForm] = useState<userFormFields>({
    firstName: "",
    lastName: "",
    email: "",
    id: null,
    phone: "",
    empTypeTxt: "Full Time",
    roleTxt: [],
    contractor: 0

  });

  useEffect(() => {
    if (currentId) {
      if (currentId === 'Add') {
        setIsAddForm(true);
      } else {
        fetchSingleRecord(currentId);
      }
    } else {
      setIsAddForm(false);
      setCurrentRecord(null);
    }
  }, [currentId]);



  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await HttpService.get(
          Constants.apiURL + Constants.rolesURL,
          { headers: { Authorization: "Bearer " + getAdmin() } },
          null
        );
        if (response.success) {
          setRoles(response.roles);

        } else {
          setRoles([])
        }

        console.log("GET Response:", response);
      } catch (error) {
        setRoles([])
        console.error("Error fetching data:", error);
      }
    };
    if (contractorList.length === 0 && isInitialLoad.current) {
      fetchContractors();
    }
    if (roles.length === 0 && isInitialLoad.current) {
      fetchRoles();
    }

    if ((searchQuery || paginate) || isInitialLoad.current) {
      fetchData();

    }
    isInitialLoad.current = false;
  }, [searchQuery, paginate]);


  const Create = async (dataObj: UserRequest) => {
    try {
      const response = await HttpService.post(
        Constants.apiURL + Constants.usersURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
        fetchData();
        clearForm(false);
      } else {
        setErrorMessage("Failed to add user. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add user. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const Update = async (dataObj: UserRequest, id: number) => {
    try {
      dataObj['id'] = id;
      const response = await HttpService.put(
        Constants.apiURL + Constants.usersURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
        fetchData();
        clearForm(false);
      } else {
        setErrorMessage("Failed to add user. Please try again.");
      }
      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add user. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const StatusUpdate = async (dataObj: StatusRequestData) => {
    try {
      const response = await HttpService.patch(
        Constants.apiURL + Constants.usersURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
        fetchData();
        clearForm(false);
      } else {
        setErrorMessage("Failed to Update Status. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add user. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const fetchContractors = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.contractorsURL,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        { search: '', limit: -1, offset: 0 },
      );
      if (response.success) {
        setContractorList(response.data.paginateRecords);

      } else {
        setErrorMessage("Failed to add user. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to Find Contractors. Please try again.");
      console.error("Error fetching data:", error);
    }
  };

  const fetchSingleRecord = async (id: any) => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.usersURL + '/' + id,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        null
      );
      if (response.success) {

        handleRowClick(response.user);
      } else {
        setErrorMessage("Failed to add user. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add user. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const checkEmail =  (email: string) => {
    return new Promise(async (resolve,reject)=>{
      try {
        let url =  Constants.apiURL + Constants.usersURL + '/findByEmail/' + email;
        if(currentRecord) {
          url = url + '?userId='+currentRecord.id
        }
        const response = await HttpService.get(
         url,
          { headers: { Authorization: "Bearer " + getAdmin() } },
          null
        );
        return resolve(response.user);
      } catch (error) {
        return resolve(null);
      }
    })
   
  };
  const fetchData = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.usersURL,
        { headers: { Authorization: "Bearer " + getAdmin() } },
        { search: searchQuery, limit: paginate.limit, offset: paginate.offset },
      );
      if (response.success) {
        setRows(response.data.paginateRecords);

        setTotalRecords(response.data.total);
      } else {
        setErrorMessage("Failed to add user. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add user. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const handleStatus = (row: any) => {
    let status = '1';
    if (row.status === '1') {
      status = '0';
    }
    if (row.status === '0') {
      status = '1';
    }
    StatusUpdate({ id: row.id, status: status });
  };







  const clearForm = (isForm: boolean) => {
    setIsAddForm(false);
    setUserForm({
      firstName: "",
      lastName: "",
      email: "",
      id: null,
      phone: "",
      empTypeTxt: "",
      roleTxt: [],
      contractor: 0

    });
    setCurrentRecord(null);
    setErrorMessage('');
    navigate('/management/users')
  };
  const handleRowClick = async (record: any) => {
    setCurrentRecord(record);
    setIsAddForm(true);
    setUserForm(
      {
        firstName: record.firstName,
        lastName: record.lastName,
        email: record.email,
        id: record.id,
        phone: record.phone,
        empTypeTxt: record.empType,
        roleTxt: record.roleId.split(','),
        contractor: record.contracterId

      }
    );

  };
  const handlePagination = (paginationData: any) => {
    console.log(paginationData);
    setPaginate(paginationData);
  };
  const handleSerchInput = async (event: any) => {
    const value: any = event.target.value;
    setSearchQuery(value);
  };

  const handleSave = async () => {
    if (validateForm()) {
      setLoading(true);
      const userReq: UserRequest = {
        email: userForm.email,
        empType: userForm.empTypeTxt,
        firstName: userForm.firstName,
        lastName: userForm.lastName,
        phone: userForm.phone,
        roleId: Array.from(new Set(userForm.roleTxt)).join(","),
        contracterId: userForm.contractor
      };
      if (currentRecord) {
        await Update(userReq, currentRecord.id);
      } else {
        await Create(userReq);
      }

      setLoading(false);
    }

  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLFormElement>
  ) => {
    const { name, value, type } = e.target;

    setUserForm((prev: any) => {
      // Check if the input is a checkbox
      if (type === "checkbox") {
        const target: any = e.target;
        const currentValues = prev[name] || []; // Get the current array or initialize it
        if (target.checked && currentValues.includes(value) === false) {
          validateField(e.target);
          return { ...prev, [name]: [...currentValues, value] };
        } else {
          validateField(e.target);
          return { ...prev, [name]: currentValues.filter((id: any) => id !== value) };
        }
      } else {
        validateField(e.target);
        // Handle other input types
        return { ...prev, [name]: value };
      }
    });
  };

  return (
    <div>
      <Header onSearch={handleSerchInput} />
      <SideBar />
      <main id="main" className="main dashboardmain">
        <PageTitle
          title={["Home", "Data Management", "User Management"]}
          links={["/dashboard", "/management", ""]}
        />

        <section className="section dashboard">
          <div className="containergrid">
            <div className="mt-2">
              {!isAddForm && (
                <Paper sx={{ width: "100%" }} className="mt-4">
                  <CustomPaginationActionsTable
                    rows={rows}
                    columns={columns}
                    totalRecords={totalRecords}
                    handlePagination={handlePagination}
                    handleRowClick={(row) => {
                      navigate('/management/users/' + row.id)
                      //console.log(data);
                    }}
                    paginationModel={{ page: paginate.offset, pageSize: paginate.limit }}
                    handleStatus={handleStatus}
                  />
                </Paper>
              )}
              {isAddForm && (
                <div className="formElement">
                  <div className="row formheader">
                    <div className="col">
                      <h1 className="pageheader">Profile</h1>
                    </div>
                  </div>

                  <form className="container loginform">
                    <div className="mb-3">
                      <label className="form-label">First Name*</label>
                      <input
                        type="text"
                        className="form-control form-control-loum"
                        id="firstNameForm"
                        name="firstName"
                        placeholder=""
                        value={userForm.firstName}
                        onChange={handleInputChange}
                      />
                      {errors.firstName && <span style={errorStyle}>{errors.firstName}</span>}
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Last Name*</label>
                      <input
                        type="text"
                        className="form-control form-control-loum"
                        id="lastNameForm"
                        name="lastName"
                        placeholder=""
                        value={userForm.lastName}
                        onChange={handleInputChange}
                      />
                      {errors.lastName && <span style={errorStyle}>{errors.lastName}</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Email ID*</label>
                      <input
                        type="email"
                        className="form-control form-control-loum"
                        id="emailForm"
                        name="email"
                        placeholder="yourname@company.com"
                        value={userForm.email}
                        onChange={handleInputChange}
                      />
                      {errors.email && <span style={errorStyle}>{errors.email}</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Phone Number</label>
                      <input
                        type="text"
                        className="form-control form-control-loum"
                        placeholder=""
                        name="phone"
                        value={userForm.phone}
                        onChange={handleInputChange}
                        id="phoneForm"
                      />
                      {errors.phone && <span style={errorStyle}>{errors.phone}</span>}
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Employee Type*</label>
                      <select
                        className="form-select"
                        id="empTypeTxtForm"
                        aria-label="Default select example"
                        name="empTypeTxt"
                        value={userForm.empTypeTxt}
                        onChange={(e: any) => {
                          handleInputChange(e);
                        }}
                      >
                        {empType.map((item, index) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                      {errors.empType && <span style={errorStyle}>{errors.empType}</span>}
                    </div>
                    <div className="row">
                      <div className="col">
                        <label className="form-label">User Roles </label>
                        <div className="form-check">
                          {roles.map((item: any, index: any) => (
                            <div className="roleEle">
                              <FormControlLabel
                                checked={
                                  userForm.roleTxt.indexOf(item.id.toString()) !== -1 ? true : false
                                }
                                style={checkStyle}
                                id={item.id + "_lbb" + index}
                                key={item.id + "_" + index}
                                control={
                                  <Checkbox
                                    name="roleTxt"
                                    onChange={handleInputChange}
                                    checked={userForm.roleTxt.includes(
                                      item.id.toString()
                                    )}
                                    value={item.id.toString()}
                                    style={checkStyle}
                                  />
                                }
                                label={item.title}
                              />

                            </div>
                          ))}

                        </div>
                        {errors.role && <span style={errorStyle}>{errors.role}</span>}
                      </div>
                    </div>
                    {userForm.roleTxt.indexOf("7") !== -1 && (
                      <div className="mb-3">
                        <label className="form-label">Contractor</label>
                        <select
                          className="form-select"
                          id="contractorSelect"
                          name="contractor"
                          value={userForm.contractor.toString()}
                          onChange={(e: any) => {
                            handleInputChange(e);
                          }}
                        >
                          <option value={0}>{"Select Contractor"}</option>
                          {contractorList.map((contractor: any, index: any) => (
                            <option value={contractor.id}>{contractor.name}</option>
                          ))}
                        </select>
                        {errors.contractor && <span style={errorStyle}>{errors.contractor}</span>}
                      </div>

                    )}

                    <div
                      className="mb-3 mt-3 txt-center"
                      style={{ textAlign: "left" }}
                    >
                      <button
                        type="button"
                        id={"createbtn"}
                        className={"btn btn-primary  createbtn"}
                        disabled={loading || Object.keys(errors).length > 0}
                        onClick={() => {
                          handleSave();
                        }}
                      >
                        {loading
                          ? "Adding wait..."
                          : currentRecord
                            ? "Update"
                            : "Create"}
                      </button>
                      <button
                        type="button"
                        id={"resetbtn"}
                        className="btn btn-primary loginbtn resetbtn"
                        onClick={() => {
                          clearForm(false);
                        }}
                      >
                        Cancel
                      </button>
                      {errorMesssage && (
                        <p className="text-danger">{errorMesssage}</p>
                      )}
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </section>
        {!isAddForm && (
          <Fab
            aria-label="add"
            sx={fabStyle}
            onClick={() => {
              navigate('/management/users/Add');
            }}
          >
            <Add />
          </Fab>
        )}
      </main>
    </div>
  );
};
const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
  backgroundColor: "#000",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#000",
    color: "#fff",
  },
};
const checkStyle = {
  fontSize: "13px",
};
const errorStyle = {
  color: "red",
};
export default Users;
