"use client";
import { Link } from "react-router-dom";
interface PageTitleProps {
  title: any; // Define the type for the title prop
  links: any
}
const PageTitle: React.FC<PageTitleProps> = ({ title: title, links: links }) => {
  return (
    <div key={title[0] + '_key'} className="pagetitle" style={containerStyle}>
      <label>{title.length > 0 ? title[title.length - 1] : 'Welcome Admin'}</label>
      {title.length > 1 && <div style={navStyle}>

        {title.map((titleData: any, index: number) => {
          return <span key={'sp_' + index}>
            <Link key={'lsnk_' + index} to={links[index]} style={navStyle}> {titleData}</Link>
            {index !== title.length - 1 && ' > '}
          </span>
        })
        }
      </div>}
    </div>
  );
};

const containerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  border: "1px solid #ccc",
};
const navStyle = {
  fontSize: '14px',
  textDecoration: 'none',
  color: '#000B29'
};
export default PageTitle;