import React, { useState } from "react";
import { Button } from "@mui/material";
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";

interface FilterComponentProps {
    onChange: (
      file: any
    ) => void;
    defaultValue: any;
    name: string
  }
const DragAndDropFileUpload: React.FC<FilterComponentProps> = ({ onChange: onChange ,defaultValue: defaultValue,name:name}) => {
  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState<File | null>(defaultValue);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      //console.log("File selected:", event.target.files[0]);
      setFile(event.target.files[0]);
      onChange(event.target.files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      console.log("File dropped:", event.dataTransfer.files[0]);
      setFile(event.dataTransfer.files[0]);
      onChange(event.dataTransfer.files[0]);
    }
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        border: dragging ? "2px dashed #4caf50" : "2px dashed #DDDFE1",
        borderRadius: "8px",
        padding: "20px",
        textAlign: "center",
        color: "#464A53",
        background: dragging ? "#f0fff0" : "transparent",
        transition: "background-color 0.3s",
        height: "200px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        style={{
          width: "100%",
          height: "100%",
          color: "#464A53",
          background: "transparent",
          border: "none",
          boxShadow:'none'
        }}
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<FileUploadOutlined fontSize="large" />}
      >
        {file ? (
          <span>{file.name}</span>
        ) : (
          <span>Drag & Drop or Click to Upload</span>
        )}
        <input
          type="file"
          hidden
          name={name}
          onChange={handleFileChange}
        />
      </Button>
    </div>
  );
};

export default DragAndDropFileUpload;
