"use client";
import React, { useEffect, useState } from "react";
import "./Index.css";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import {
  Checkbox,
  Fab,
  FormControlLabel,
  Paper,
  TextareaAutosize,

} from "@mui/material";
import { Add } from "@mui/icons-material";
import PageTitle from "../../components/PageTitle";
import Constants from "../../utils/contants";
import HttpService from "../../services/HttpService";
import { useStore } from "../../utils/store";
import Switch from "@mui/material/Switch";
import CustomPaginationActionsTable from "../../components/TablePagination";
import StatusRequestData from "../../Models/StatusRequest";
enum InductionStatus {
  NotApplicable = "0",
  Yes = "1",
  No = "2",
}
const Contractor: React.FC = () => {
  const { getAdmin } = useStore();

  // Table Columns Definition
  const columns: any[] = [
    { field: "name", headerName: "Name", width: 130 },
    { field: "address", headerName: "Address", width: 200 },
    { field: "email", headerName: "Email Address", width: 200 },
    { field: "phone", headerName: "Phone Number", width: 130 },
    { field: "creditLimit", headerName: "Credit Limit", width: 130 },
    { field: "contractorID", headerName: "Contractor ID", width: 130 },
    { field: "abn", headerName: "ABN Number", width: 150 },
    {
      field: "status",
      headerName: "Active",
      width: 130,
      renderCell: (value: any) => (
        <Switch
          checked={value === "1"}
          size="small"
          color="success"
          inputProps={{ "aria-label": "controlled" }}
        />
      ),
    },
    {
      field: "created",
      headerName: "Date Modified",
      width: 130,
      valueGetter: (value: any) =>
        new Date(value).toLocaleDateString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
    },
  ];
  // State Management
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const [paginate, setPaginate] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    offset: 0,
  });
  const [contractorForm, setContractorForm] = useState({
    name: "",
    id: null,
    email: "",
    address: "",
    phone: "",
    contractorID: "",
    creditLimit: "",
    notes: "",
    completed: InductionStatus.NotApplicable,
    abn: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    contractorID: "",
    abn: ""
  });

  useEffect(() => {
    fetchData();
  }, [searchQuery, paginate]);

  // Fetch Data
  const fetchData = async () => {
    try {
      const response = await HttpService.get(
        Constants.apiURL + Constants.contractorsURL,
        { headers: { Authorization: `Bearer ${getAdmin()}` } },
        { search: searchQuery, limit: paginate.limit, offset: paginate.offset }
      );
      if (response.success) {
        setRows(response.data.paginateRecords);
        setTotalRecords(response.data.total);
      } else {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      }
    } catch (error) {
      setErrorMessage(Constants.COMMON_SERVER_ERROR);
    }
  };

  // Form Handling
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setContractorForm((prev) => ({ ...prev, [name]: value }));
    if(name!=='address' && name!=='completed' && name!=='notes' && name!=='creditLimit'){
      validateField(e.target);
    }
         
  };



  const clearForm = () => {
    setIsAddForm(false);
    setContractorForm({
      name: "",
      email: "",
      address: "",
      phone: "",
      contractorID: "",
      creditLimit: "",
      notes: "",
      completed: InductionStatus.NotApplicable,
      abn: "",
      id: null
    });
    setErrorMessage(null);
    setCurrentRecord(null);
  };

  const handleSave = async () => {
   
    if (validateForm()) {
      setLoading(true);
      const endpoint = Constants.apiURL + Constants.contractorsURL;
      if (currentRecord) {
        contractorForm['id'] = currentRecord.id;
      }
      let response: any;
      try {
        if (currentRecord) {
          response = await HttpService.put(endpoint, contractorForm, {
            headers: { Authorization: `Bearer ${getAdmin()}` },
          });
        } else {
          response = await HttpService.post(endpoint, contractorForm, {
            headers: { Authorization: `Bearer ${getAdmin()}` },
          });
        }
        if (response.success) {
          fetchData();
          clearForm();
        } else {
          setErrorMessage(Constants.COMMON_SERVER_ERROR);
        }
      } catch {
        setErrorMessage(Constants.COMMON_SERVER_ERROR);
      } finally {
        setLoading(false);
      }
    }

  };

  const handleRowClick = (record: any) => {
    setCurrentRecord(record);
    setContractorForm({ ...record });
    setIsAddForm(true);
  };

  const handlePagination = (paginationData: any) => setPaginate(paginationData);

  const handleStatus = (row: any) => {
    let status = '1';
    if (row.status === '1') {
      status = '0';
    }
    if (row.status === '0') {
      status = '1';
    }
    StatusUpdate({ id: row.id, status: status });
  };

  const StatusUpdate = async (dataObj: StatusRequestData) => {
    try {
      const response = await HttpService.patch(
        Constants.apiURL + Constants.contractorsURL,
        dataObj,
        { headers: { Authorization: "Bearer " + getAdmin() } }
      );
      if (response.success) {
        fetchData();
        clearForm();
      } else {
        setErrorMessage("Failed to Update Status. Please try again.");
      }

      console.log("GET Response:", response);
    } catch (error) {
      setErrorMessage("Failed to add user. Please try again.");
      console.error("Error fetching data:", error);
    }
  };
  const checkEmail = (email: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        let url = Constants.apiURL + Constants.contractorsURL + '/findByEmail/' + email;
        if (currentRecord) {
          url = url + '?contractorId=' + currentRecord.id
        }
        const response = await HttpService.get(
          url,
          { headers: { Authorization: "Bearer " + getAdmin() } },
          null
        );
        return resolve(response.contractor);
      } catch (error) {
        return resolve(null);
      }
    })

  };
  const validateField = async (element: any) => {
    const elementName = element.name;
    const newErrors: any = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/; // Validates a 10-digit phone number
    switch (elementName) {
      case 'name':
        if (!element.value.trim()) {
          newErrors.name = "Firstname is required.";
        }
        break;
      case 'contractorID':
        if (!element.value.trim()) {
          newErrors.contractorID = "ContractorID is required.";
        }
        break;
      case 'email':
        if (!element.value) {
          newErrors.email = "Email is required.";
        } else if (!emailRegex.test(element.value)) {
          newErrors.email = "Invalid email address.";
        } else {
          const isEmailExists = await checkEmail(element.value);
          console.log(isEmailExists);
          if (isEmailExists) {
            newErrors.email = "Email ID already Exists";
          } else {
            newErrors.email = '';
          }
        }
        break;

      case 'phone':
        if (!phoneRegex.test(element.value) && element.value) {
          newErrors.phone = "Invalid Australian phone number.";
        }
        break;
      case 'abn':
        const abnRegex = /^\d{11}$/;
        if (!abnRegex.test(element.value) && element.value) {
          newErrors.abn = "Invalid ABN. Must be 11 digits.";
        }
        break;


    }
    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  }

  const validateForm = () => {
    const newErrors: any = {};

    // Name Validation
    if (!contractorForm.name.trim()) {
      newErrors.name = "Name is required.";
    }
    if (!contractorForm.contractorID) {
      newErrors.contractorID = "ContractorID is required.";
    }
    const abnRegex = /^\d{11}$/;
    if (!abnRegex.test(contractorForm.abn)) {
      newErrors.abn = "Invalid ABN. Must be 11 digits.";
    }
    // Email Validation
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!contractorForm.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(contractorForm.email)) {
      newErrors.email = "Invalid email address.";
    }
    // Phone Number Validation
    const phoneRegex = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/; // Validates a 10-digit phone number
    if (!phoneRegex.test(contractorForm.phone) && contractorForm.phone) {
      newErrors.phone = "Invalid Australian phone number.";
    }


    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  return (
    <div>
      <Header onSearch={(e) => setSearchQuery(e.target.value)} />
      <SideBar />
      <main id="main" className="main dashboardmain">
        <PageTitle
          title={["Home", "Data Management", "Contractor Management"]}
          links={["/dashboard", "/management", ""]}
        />

        <section className="section dashboard">
          <div className="containergrid">
            {!isAddForm ? (
              <Paper sx={{ width: "100%" }} className="mt-4">
                <CustomPaginationActionsTable
                  rows={rows}
                  columns={columns}
                  totalRecords={totalRecords}
                  handlePagination={handlePagination}
                  handleRowClick={handleRowClick}
                  handleStatus={handleStatus}
                  paginationModel={{
                    page: paginate.offset,
                    pageSize: paginate.limit,
                  }}
                />
              </Paper>
            ) : (
              <div className="formElement">
                <form className="container loginform">
                  <div className="mb-3">
                    <label className="form-label">Name*</label>
                    <input
                      type="text"
                      className="form-control form-control-loum"
                      id="firstNameForm"
                      name="name"
                      placeholder="Enter contractor name"
                      value={contractorForm.name}
                      onChange={handleInputChange}
                    />
                    {errors.name && <span className="bad-field">{errors.name}</span>}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">ABN Number*</label>
                    <input
                      type="text"
                      className="form-control form-control-loum"
                      id="abnForm"
                      name="abn"
                      placeholder="Enter 11-digit ABN number"
                      value={contractorForm.abn}
                      onChange={handleInputChange}
                    />
                    {errors.abn && <span className="bad-field">{errors.abn}</span>}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      className="form-control form-control-loum"
                      id="lastNameForm"
                      name="address"
                      placeholder="Enter address"
                      value={contractorForm.address}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Email Address*</label>
                    <input
                      type="email"
                      className="form-control form-control-loum"
                      id="emailForm"
                      name="email"
                      placeholder="Enter email address"
                      value={contractorForm.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && <span className="bad-field">{errors.email}</span>}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Phone Number*</label>
                    <input
                      type="text"
                      className="form-control form-control-loum"
                      name="phone"
                      placeholder="Enter phone number"
                      value={contractorForm.phone}
                      onChange={handleInputChange}
                    />
                    {errors.phone && <span className="bad-field">{errors.phone}</span>}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Contractor ID*</label>
                    <input
                      type="text"
                      className="form-control form-control-loum"
                      name="contractorID"
                      placeholder="Enter contractor ID"
                      value={contractorForm.contractorID}
                      onChange={handleInputChange}
                    />
                    {errors.contractorID && <span className="bad-field">{errors.contractorID}</span>}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Credit Limit</label>
                    <input
                      type="text"
                      className="form-control form-control-loum"
                      name="creditLimit"
                      placeholder="Enter credit limit"
                      value={contractorForm.creditLimit}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="row">
                    <div className="col">
                      <label className="form-label">
                        Loumac Induction Completed
                      </label>
                      <div className="form-check">
                        <div className="roleEle">
                          <FormControlLabel
                            style={checkStyle}
                            id={"completed_1"}
                            key={"completed_1"}
                            control={
                              <Checkbox
                                onChange={handleInputChange}
                                name="completed"
                                checked={
                                  contractorForm.completed ===
                                  InductionStatus.Yes
                                }
                                value={InductionStatus.Yes}
                                style={checkStyle}
                              />
                            }
                            label={"Yes"}
                          />
                          <FormControlLabel
                            style={checkStyle}
                            id={"completed_2"}
                            key={"completed_2"}
                            control={
                              <Checkbox
                                onChange={handleInputChange}
                                name="completed"
                                checked={
                                  contractorForm.completed ===
                                  InductionStatus.No
                                }
                                value={InductionStatus.No}
                                style={checkStyle}
                              />
                            }
                            label={"No"}
                          />
                          <FormControlLabel
                            style={checkStyle}
                            id={"completed_0"}
                            key={"completed_0"}
                            control={
                              <Checkbox
                                onChange={handleInputChange}
                                name="completed"
                                checked={
                                  contractorForm.completed ===
                                  InductionStatus.NotApplicable
                                }
                                value={InductionStatus.NotApplicable}
                                style={checkStyle}
                              />
                            }
                            label={"N/A"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Notes</label>
                    <TextareaAutosize
                      className="form-control form-control-loum"
                      placeholder="Enter notes"
                      name="notes"
                      value={contractorForm.notes}
                      onChange={handleInputChange}
                      minRows={7}
                    />
                  </div>
                  <div
                    className="mb-3 mt-3 txt-center"
                    style={{ textAlign: "left" }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary loginbtn createbtn"
                      disabled={loading || Object.keys(errors).length > 0}
                      onClick={handleSave}
                    >
                      {loading
                        ? "Saving..."
                        : currentRecord
                          ? "Update"
                          : "Create"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary loginbtn resetbtn"
                      onClick={clearForm}
                    >
                      Cancel
                    </button>
                    {errorMessage && (
                      <p className="text-danger">{errorMessage}</p>
                    )}
                  </div>
                </form>
              </div>
            )}
          </div>
        </section>
        {!isAddForm && (
          <Fab
            aria-label="add"
            sx={fabStyle}
            onClick={() => setIsAddForm(true)}
          >
            <Add />
          </Fab>
        )}
      </main>
    </div>
  );
};

const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
  backgroundColor: "#000",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#000",
    color: "#fff",
  },
};
const checkStyle = {
  fontSize: "13px",
};
export default Contractor;
