import React, { createContext, useContext, useState } from 'react';
import { getLocalAccessToken } from './contants';

interface Store {
  isAdmin: string;
  setIsAdmin: (value: string) => void;
  getAdmin: () => string;
}

// Create the context with default values
const StoreContext = createContext<Store | undefined>(undefined);

// Provider component
export const StoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState<any>(null);
  const getAdmin = () => {
    let strToken: any = '';
    if (isAdmin) {
      return isAdmin;
    } else {
      strToken = getLocalAccessToken();
      return strToken;
    }
  }
  return (
    <StoreContext.Provider value={{ isAdmin, setIsAdmin, getAdmin }}>
      {children}
    </StoreContext.Provider>
  );
};

// Custom hook to use the store
export const useStore = (): Store => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return context;
};
